import React, { useRef, useEffect } from 'react';
import ProductDisplay from './ProductDisplay';
import TipsDisplay from './TipsDisplay';
import '../../App.css';
const Modal = ({ handleClose, show, contentType }) => {
  const modalRef = useRef();
  const handleOutsideClick = (e) => {
    if (contentType === 'tips' && modalRef.current && !modalRef.current.contains(e.target)) {
      handleClose();
    }
  };
  useEffect(() => {
    if (show && contentType === 'tips') {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [show, handleClose, contentType]);
  const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  return (
    <div className={showHideClassName}>
      <div className={`modal-content ${contentType === 'tips' ? 'tips-modal-content' : 'product-modal-content'}`} ref={modalRef}>
        {contentType === 'product' && (
          <>
            <span className='close' onClick={handleClose}>&times;</span>
            <ProductDisplay />
          </>
        )}
        {contentType === 'tips' && <TipsDisplay />}
      </div>
    </div>
  );
};
export default Modal;









