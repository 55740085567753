import React, { useState, useEffect, useRef} from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import MenuIcon from '@mui/icons-material/Menu';
import StarIcon from '@mui/icons-material/Star';
import insta from '../../assests/Instagram.svg';
import linked from '../../assests/Linkedin.svg';
import face from '../../assests/facebook.svg';
import tik_tok from '../../assests/tik_tok.svg';
import PlacholderImage from '../../assests/GreyBoxLogo.svg';
import Modal from '../Payment/modal';
import MainLogo from '../../assests/genie.svg'


const firebaseConfig = {
    apiKey: "AIzaSyDnW3Gd5E5zYvOkeCl6jDqyFAyBDVQj_5Q",
    authDomain: "geni-e.firebaseapp.com",
    projectId: "geni-e",
    storageBucket: "geni-e.appspot.com",
    messagingSenderId: "1015653741676",
    appId: "1:1015653741676:web:5f259c1097532485855449"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function ImageGallery() {
    const navigate = useNavigate();
    const uidRef = useRef(null);
    const [userDetails, setUserDetails] = useState('');
    const [userCredits, setUserCredits] = useState('');
    const [message, setMessage] = useState("");
    const [userImages, setUserImages] = useState([]);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [clickedImageUrl, setClickedImageUrl] = useState('');
    const [clickedbackground, setClickedBackgroundUrl] = useState('');
    const [modalContentType, setModalContentType] = useState('');


    // const API_BASE_URL = 'http://localhost:80';
    const API_BASE_URL = 'https://flask.geni-e.com';

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
            // User is signed in.
            const { displayName, uid, email } = user;
            setUserDetails({ displayName, uid, email });
            uidRef.current = uid;


            // Fetch user credits from the Flask route upon component mount
            fetch(`${API_BASE_URL}/user-credits`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid: uid }),
            })
            .then(response => response.json())
            .then(data => {
                // Set the retrieved user credits to state
                setUserCredits(data.Credits);
            })
            .catch(error => {
                console.error('Error fetching user credits:', error);
            });

            // Fetch user images from the Flask route upon component mount
            fetch(`${API_BASE_URL}/user-images`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ uid: uid }),
            })
            .then(response => response.json())
            .then(data => {
                // Handle the fetched user images (data.images) as needed
                setUserImages(data.images)
                console.log('User Images:', data.images);
            })
            .catch(error => {
                console.error('Error fetching user images:', error);
            });

            const query = new URLSearchParams(window.location.search);

            if (query.get("success")) {
            setMessage("Order placed! You will receive an email confirmation.");
            const sessionId = query.get("session_id");
            const PID = query.get("PID");
            console.log('uuid',uid)
    
            fetch(`${API_BASE_URL}/update-credits`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    // You might need to include other headers such as authentication tokens if required
                },
                body: JSON.stringify({ sessionId, PID, uid }),
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json().then(data => {
                            setMessage("Credits Added Successfully");
                            navigate('/', {replace: true});
                            console.log(data);
                        });
                    } else if (response.status === 400) {
                        return response.json().then(data => {
                            setMessage("Credits already Added"); // Assuming the server sends an error message in the response body
                            navigate('/', {replace: true});
                            console.log(data);
                        });
                    } else {
                        setMessage("Server Error: Unable to process your request");
                        throw new Error('Server Error');
                    }
                })
                .catch(error => {
                    setMessage("Network Error: Unable to fetch data");
                    console.error('Error:', error);
                });
            }
            
            if (query.get("canceled")) {
            setMessage("Order cancelled -- continue to shop around and checkout when you're ready.");
            }


        } else {
            // User is signed out.
            setUserDetails(null);
        }
        });
    
        return () => {
        // Unsubscribe from the onAuthStateChanged listener when the component unmounts.
        unsubscribe();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    
    const HandleHome = () => {
        navigate('/', {replace: true});
    };
    
    const handleLogout = () => {
        signOut(auth)
        .then(() => {
            // Sign-out successful.
            Cookies.remove('user');
            navigate('/', {replace: true});
            console.log('User logged out successfully');
        })
        .catch((error) => {
            // An error happened.
            console.error('Error during logout:', error);
        });
    };

    
      const handleModalOpen = (contentType) => {
        setModalContentType(contentType);
        setIsModalOpen(true); // Open the modal
      };
    
      const handleModalClose = () => {
        setIsModalOpen(false);
      };
    
      const closeModal = () => {
        setModalVisible(false);
        setClickedImageUrl('');
      };
    

    const previousGenImage = (imageUrl) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = imageUrl;
        downloadLink.download = `Previous_Gen.jpg`;
        downloadLink.click();
    };

    const HandleGallery = () => {
        navigate('/gallery', {replace: true});
    };

  return (
    <> 
        <div className='nav'>
            <a href="#" onClick={HandleHome}>
            <img className='MainLogo' src={MainLogo} alt='Genie logo in orange. The logo is the Orange letters g, e, n, i, e' />
            </a>
            <h1>Hello, {userDetails.displayName}</h1>
            <div className='creditInfo'>
                <p className='NoC'>{userCredits}</p>
                <div className='creditText'>
                    <h3>Credit Balance</h3>
                    <p className='addMoreCredits' onClick={() => handleModalOpen('product')}>Want to add more?</p>
                </div>
            </div>
        </div>

        {message && <section><p>{message}</p></section>}

        <Modal show={isModalOpen} handleClose={handleModalClose}/>
        
        <Modal
            handleClose={handleModalClose}  // replace with your actual handleClose function
            show={isModalOpen}              // replace with your actual modal visibility state
            contentType={modalContentType}  // the state that tracks what content to show in the modal
          />


        <div className='MainGalleryContainer'>
            <div className='GallerySideBarSection'>
                {!isMenuOpen ? (
                    <MenuIcon onClick={toggleMenu} className='open hamburger'/>
                ) : null}

                {isMenuOpen && (
                    <>
                    <div className='close-menu'>
                        <y  onClick={toggleMenu} className='hamburger'/>
                    </div>
                    <div className='test'>
                        <div className="Sidebar">
                        <br/><br/>
                        <button onClick={HandleHome} className='SideButton'>Home</button>
                        <br/>
                        <button onClick={() => handleModalOpen('product')} className='SideButton'>
                            <StarIcon className='star'/> Add credits
                        </button>
                        <br/>
                        <button onClick={HandleGallery} className='SideButton'>Image gallery</button>
                        <br/>
                        {/* <h3>Share with us!</h3> */}
                        <div className='logos top'>
                            <a href='https://instagram.com/geni_e.ai?igshid=MzRlODBiNWFlZA==' target="_blank" rel="noopener noreferrer"><img className='social logos' src={insta} alt='Instagram Logo' /></a>
                            <a href='https://www.linkedin.com/company/geni-e' target="_blank" rel="noopener noreferrer"><img className='social logos' src={linked} alt='Linkedin Logo' /></a>
                        </div>
                        <div className='logos'>
                            <a href='https://www.facebook.com/profile.php?id=61551112913259' target="_blank" rel="noopener noreferrer"><img className='social logos' src={face} alt='facebook Logo' /></a>
                            <a href='https://www.tiktok.com/@geni_e' target="_blank" rel="noopener noreferrer"><img className='social logos' src={tik_tok} alt='tik_tok Logo' /></a>
                        </div>
                        <br />
                        <button className='SideButton' onClick={handleLogout}>Logout</button>
                        </div>
                    </div>
                    </>
                )}
            </div>

            <div className='MainGallerySection'>
                <h1>Gallery</h1>
                <h2 className='title'>Your past generated images</h2>
                {userImages.length > 0 ? (
                    <div className='GalleryUpload-grid-container'>
                        {userImages.map((imageUrl, index) => (
                            imageUrl ? (
                                <img
                                    key={index}
                                    src={imageUrl}
                                    alt={`Previous Gen ${index}`}
                                    onClick={() => previousGenImage(imageUrl)}
                                    className='GalleryClickable-image'
                                />
                            ) : (
                                <img
                                    key={index}
                                    src="path_to_your_grey_box_image.jpg"
                                    alt={`Empty Slot ${index}`}
                                    className='GalleryClickable-image'
                                    onClick={HandleHome}
                                />
                            )
                        ))}
                        {/* Add grey boxes for remaining slots */}
                        {Array.from({ length: 9 - userImages.length }, (_, index) => (
                            <img
                                key={`empty-${index}`}
                                src={PlacholderImage}
                                alt={`Empty Slot ${index}`}
                                className='GalleryClickable-image'
                                onClick={HandleHome}
                            />
                        ))}
                    </div>
                ) : (
                    <div className='GalleryUpload-grid-container'>
                        {/* Display nine placeholders */}
                        {Array.from({ length: 9 }, (_, index) => (
                            <img
                                key={`empty-${index}`}
                                src={PlacholderImage}
                                alt={`Empty Slot ${index}`}
                                className='GalleryClickable-image'
                                onClick={HandleHome}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    </>
  )
}

export default ImageGallery