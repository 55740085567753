import { Navigate } from 'react-router-dom';
// import { getAuth } from 'firebase/auth';
import Cookies from 'js-cookie';

export default function ProtectedRoute({children}) {
  // const isAuthenticated = !!getAuth().currentUser;
  const isAuthenticated = !!Cookies.get('user');

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return children;
}