import { useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider, getAuth, signInWithPopup } from 'firebase/auth';
import GoogleBtn from '../../assests/Google.svg';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const firebaseConfig = {
    apiKey: "AIzaSyDnW3Gd5E5zYvOkeCl6jDqyFAyBDVQj_5Q",
    authDomain: "geni-e.firebaseapp.com",
    projectId: "geni-e",
    storageBucket: "geni-e.appspot.com",
    messagingSenderId: "1015653741676",
    appId: "1:1015653741676:web:5f259c1097532485855449"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const SocialLogin = () => {

  const navigate = useNavigate();
  const API_BASE_URL = 'https://flask.geni-e.com';

  // Check if the user is already authenticated using the cookie
  useEffect(() => {
    const userCookie = Cookies.get('user');
    if (userCookie) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate]);

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      // You can access user information here, e.g., user.displayName, user.email, etc.
      Cookies.set('user', 'authenticated', { expires: 1 }); // Cookie expires in 24 hours
      
      const displayName = user.displayName
      const uid = user.uid

      // Send data to Flask backend using fetch
      fetch(`${API_BASE_URL}/createuser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ displayName, uid }),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Data sent to Flask backend:', data);
      })
      .catch(error => {
        console.error('Error sending data to Flask backend:', error);
      });
      
      navigate('/dashboard');
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <button className="google-btn" onClick={handleGoogleLogin}>
      <img className='google' src={GoogleBtn} alt="Google" /> 
    </button>
  );
};

export default SocialLogin;
