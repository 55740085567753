import React, { createContext, useContext, useEffect, useState } from 'react';

const CookiesContext = createContext();

export function CookiesProvider({ children }) {
  const [cookiesAccepted, setCookiesAccepted] = useState(null);

  useEffect(() => {
    const cookiesAcceptedValue = localStorage.getItem('cookiesAccepted');
    if (cookiesAcceptedValue !== null) {
      setCookiesAccepted(cookiesAcceptedValue === 'true');
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setCookiesAccepted(true);
  };

  const rejectCookies = () => {
    localStorage.setItem('cookiesAccepted', 'false');
    setCookiesAccepted(false);
  };

  const value = {
    cookiesAccepted,
    acceptCookies,
    rejectCookies
  };

  return <CookiesContext.Provider value={value}>{children}</CookiesContext.Provider>;
}

export function useCookies() {
  return useContext(CookiesContext);
}