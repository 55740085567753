import React from "react";
import promptHelpImage from '../../assests/prompthelp3.png'; // Update this path to the location of your image

const TipsDisplay = () => {
  return (
    <>
      <section>
        <div className="tips">
          <div className="description">
           
            {/* Displaying the image */}
            <img src={promptHelpImage} alt="Prompt Help" />
          </div>
        </div>
      </section>
    </>
  );
};

export default TipsDisplay;