import React, { useRef, useEffect } from 'react';
import '../../App.css';

const GenericImageModal = ({ imageUrl, handleClose, show, onSave }) => {
  const modalRef = useRef();

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, handleClose]);

  const showHideClassName = show ? 'modal display-block' : 'modal display-none';

  return (
    <div className={showHideClassName}>
      <div className='generic-modal-overlay' ref={modalRef}>
        <div className='generic-modal-content'>
          <span className='close' onClick={handleClose}>&times;</span>
          <img className="modal-image" src={imageUrl} alt="Selected background" />
          <br />
          <button className="save-button" onClick={onSave}>
            Download Image
          </button>
        </div>
      </div>
    </div>
  );
};

export default GenericImageModal;