import EmailPasswordAuth from "./EmailLogin";
import contact from '../../assests/Group.svg';
import { Link } from 'react-router-dom';
import small_logo from "../../assests/monogram.svg"
import HomeIcon from '@mui/icons-material/Home';

function Login() {
  return (
  <>
    <div className="NavBar">
      <img className="geni-e_logo" src={small_logo} alt="geni-e logo in orange " />
      <Link to="https://www.geni-e.com" target="_blank" rel="noopener noreferrer">
        <button className="Return-Home" >Home page <HomeIcon /></button>
      </Link>
    </div>
    <div className='grid-container'>
      <div className='logins'>
          <h2>Hey, enter your details to access <br></br>your account</h2>
          <EmailPasswordAuth />
      </div>
      <div className='login-image'><img src={contact} alt='person running with paper areoplane'></img></div>
    </div>
    <div>
    </div>
  </>
  );
}

export default Login;