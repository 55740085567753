import React from "react";

const ProductDisplay = () => {
  return (
    <>
        <section>
            <div className="product">
                <div className="description">
                <h3>5 Credit Bundle</h3>
                <h5>£3.99</h5>
                </div>
            </div>
            <form action="https://flask.geni-e.com/create-checkout-session/5" method="POST">
                <button className="productSubmit" type="submit">Checkout</button>
            </form>
        </section>

        <section>
            <div className="product">
                <div className="description">
                <h3>25 Credit Bundle</h3>
                <h5>£7.99</h5>
                </div>
            </div>
            <form action="https://flask.geni-e.com/create-checkout-session/25" method="POST">
                <button className="productSubmit" type="submit">Checkout</button>
            </form>
        </section>

        <section>
            <div className="product">
                <div className="description">
                <h3>50 Credit Bundle</h3>
                <h5>£9.99</h5>
                </div>
            </div>
            <form action="https://flask.geni-e.com/create-checkout-session/50" method="POST">
                <button className="productSubmit" type="submit">Checkout</button>
            </form>
        </section>
    </>
  );
};

export default ProductDisplay;