import React, { useState, useEffect, useRef} from 'react';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Modal from './Payment/modal';
import GenericImageModal from './Editor/GenericImageModal';


// SVG's
import insta from '../assests/Instagram.svg';
import linked from '../assests/Linkedin.svg';
import face from '../assests/facebook.svg';
import tik_tok from '../assests/tik_tok.svg';
import MainLogo from '../assests/genie.svg';
import SearchIcon from '../assests/SearchIcon3.svg';
import Loading from '../assests/Loading.gif';
import MonoB from '../assests/monogram2.svg';
import DownArrow from '../assests/Down_Arrows.svg';

// Mui
import MenuIcon from '@mui/icons-material/Menu';
import StarIcon from '@mui/icons-material/Star';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDnW3Gd5E5zYvOkeCl6jDqyFAyBDVQj_5Q",
    authDomain: "geni-e.firebaseapp.com",
    projectId: "geni-e",
    storageBucket: "geni-e.appspot.com",
    messagingSenderId: "1015653741676",
    appId: "1:1015653741676:web:5f259c1097532485855449"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function Dashboard() {

  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState('');
  const [userCredits, setUserCredits] = useState('');
  const [textBoxValue, setTextBoxValue] = useState('');
  const [modifyValue, setModifyValue] = useState('');
  // const [generatedImageUrls, setGeneratedImageUrls] = useState(Array.from({ length: 0 }, () => ''));
  const [genericImageUrls, setGenericImageUrls] = useState(Array.from({ length: 0 }, () => ''));
  const [genericBackgroundUrls, setGenericBackgroundUrls] = useState(Array.from({ length: 0 }, () => ''));
  const [bgRemovedImage, setBgRemovedImage] = useState('');
  const [prevPrompt, setPrevPrompt] = useState('');
  const [clickedImageUrl, setClickedImageUrl] = useState('');
  const [clickedbackground, setClickedBackgroundUrl] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isModifyLoading, setIsModifyLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const uidRef = useRef(null);
  const textBoxRef = useRef(null);
  const [imageUploaded, setImageUploaded] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState('');
  const [uploadedFile, setUploadedFile] = useState('');
  const [modalContentType, setModalContentType] = useState('');


  // const [isModal2Visible, setModal2Visible] = useState(false);

  // const handleShowModal2 = () => {
  //   setModal2Visible(true);
  // };

  // const handleCloseModal2 = () => {
  //   setModal2Visible(false);
  // };

  // error Messages
  const [fileSizeError, setFileSizeError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modifyErrorMessage, setModifyErrorMessage] = useState("");
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

  const API_BASE_URL = 'https://flask.geni-e.com';
  //const API_BASE_URL = 'http://localhost:80';

  useEffect(() => {

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        const { displayName, uid, email } = user;
        setUserDetails({ displayName, uid, email });
        uidRef.current = uid;


        // Fetch user credits from the Flask route upon component mount
        fetch(`${API_BASE_URL}/user-credits`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ uid: uid }),
        })
        .then(response => response.json())
        .then(data => {
            // Set the retrieved user credits to state
            setUserCredits(data.Credits);
        })
        .catch(error => {
            console.error('Error fetching user credits:', error);
        });

        const query = new URLSearchParams(window.location.search);

        if (query.get("success")) {
          setMessage("Order placed! You will receive an email confirmation.");
          const sessionId = query.get("session_id");
          const PID = query.get("PID");
          console.log('uuid',uid)
  
          fetch(`${API_BASE_URL}/update-credits`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // You might need to include other headers such as authentication tokens if required
            },
            body: JSON.stringify({ sessionId, PID, uid }),
          })
            .then(response => {
                if (response.status === 200) {
                    return response.json().then(data => {
                        setMessage("Credits Added Successfully");
                        navigate('/', {replace: true});
                        console.log(data);
                    });
                } else if (response.status === 400) {
                    return response.json().then(data => {
                        setMessage("Credits already Added"); // Assuming the server sends an error message in the response body
                        navigate('/', {replace: true});
                        console.log(data);
                    });
                } else {
                    setMessage("Server Error: Unable to process your request");
                    throw new Error('Server Error');
                }
            })
            .catch(error => {
                setMessage("Network Error: Unable to fetch data");
                console.error('Error:', error);
            });
        }
        
        if (query.get("canceled")) {
          setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
        }

      } else {
        // User is signed out.
        setUserDetails(null);
      }
    });
  

    return () => {
      // Unsubscribe from the onAuthStateChanged listener when the component unmounts.
      unsubscribe();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
      signOut(auth)
      .then(() => {
          // Sign-out successful.
          Cookies.remove('user');
          navigate('/', {replace: true});
          console.log('User logged out successfully');
      })
      .catch((error) => {
          // An error happened.
          console.error('Error during logout:', error);
      });
  };


  const handleImageClick = (imageUrl) => {
    // Creates a new URL for the route
    const routeUrl = `/image/${encodeURIComponent(imageUrl)}/${encodeURIComponent(bgRemovedImage)}/${encodeURIComponent(textBoxValue)}/${encodeURIComponent(height)}/${encodeURIComponent(width)}`;

    // Opens the route URL in a new tab
    window.open(routeUrl, '_blank');
  };

  const handleGenericImageClick = ({ imageUrl, backgroundUrl }) => {
    setClickedImageUrl(imageUrl);
    setClickedBackgroundUrl(backgroundUrl);
    setModalVisible(true);
  };

  const handleModalOpen = (contentType) => {
    setModalContentType(contentType);
    setIsModalOpen(true); // Open the modal
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const closeModal = () => {
    setModalVisible(false);
    setClickedImageUrl('');
  };

  const downloadImage = () => {
    const byteCharacters = atob(clickedImageUrl);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'image.png'); // Set the desired file name
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleSave = () => {

    fetch(`${API_BASE_URL}/saveimageb64`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        b64_image: clickedImageUrl,
        uid: userDetails.uid,
        background: clickedbackground
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        console.log('API response:', data);
        downloadImage();
        closeModal(); // Close the modal after save
      })
      .catch((error) => {
        console.error('Error sending data to API:', error);
        // Handle errors here
      });
  };

  const handleHome = () => {
    navigate('/', {replace: true});
  };
  
  const HandleGallery = () => {
    navigate('/gallery', {replace: true});
  };

  const handleFileUpload = (event) => {
    setImageUploaded(false);
    setUploadedImageUrl('');
    setFileSizeError('');
    setErrorMessage('');
    const file = event.target.files[0];

    if (file.size > MAX_FILE_SIZE) {
      // Show an error message or handle the oversized file here
      setFileSizeError('File size exceeds 5MB limit');
      setIsLoading(false);
      return;
    }

    if (event.target.files.length > 0) {
      const uploadedImage = URL.createObjectURL(event.target.files[0]);
      setUploadedFile(event.target.files[0]);
      setUploadedImageUrl(uploadedImage);
      setImageUploaded(true);
    } else {
      setImageUploaded(false);
      setUploadedImageUrl('');
    }
  };



  const MainUpload = (event) => {
    console.log(uploadedFile)
    console.log('textbox', textBoxValue)
    setGenericImageUrls([]);
    setModifyValue('')
    console.log(prevPrompt)
    const file = uploadedFile

    if (!uploadedFile) {
      // Show an error message or handle the case where no file is uploaded
      setErrorMessage('Please Upload a file to continue')
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      // Show an error message or handle the oversized file here
      setFileSizeError('File size exceeds 5MB limit');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    if (userCredits < 2) {
      setIsModalOpen(true);
      setIsLoading(false);
    } else {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('uid', userDetails.uid);
      formData.append('prompt', textBoxValue);
      formData.append('prevPrompt', '');
      
      fetch(`${API_BASE_URL}/getimages`, {
          method: 'POST',
          body: formData
      })
      .then(res => res.json())
      .then(data => {
          console.log('API response:', data);
          setIsLoading(false);
          setGenericImageUrls(data.base64_data);
          console.log('Image', data.base64_data)
          setPrevPrompt(data.prompt);
          console.log('Array', genericImageUrls);
          event.target.value = null;
      })
      .catch(error => {
          console.error('Error sending data to API:', error);
          setIsLoading(false);
          setErrorMessage('Missed the mark? Geni-e is here to help. Please try again.')
      });
    }
  }

  const ModifyPrompt = (event) => {
    console.log(uploadedFile)
    console.log('textbox', textBoxValue)
    const file = uploadedFile

    setIsModifyLoading(true);

    const formData = new FormData();
    formData.append('image', file);
    formData.append('uid', userDetails.uid);
    formData.append('modifyValue', modifyValue);
    formData.append('prevPrompt', prevPrompt);
    setModifyErrorMessage('')
    
    fetch(`${API_BASE_URL}/getimages`, {
        method: 'POST',
        body: formData
    })
    .then(res => res.json())
    .then(data => {
        console.log('API response:', data);
        setIsModifyLoading(false);
        const newImages = data.base64_data || []; // Ensure data.base64_data is an array
        setGenericImageUrls(prevImages => [...prevImages, ...newImages]);
        event.target.value = null;
    })
    .catch(error => {
        console.error('Error sending data to API:', error);
        setIsModifyLoading(false);
        setModifyErrorMessage('Missed the mark? Geni-e is here to help. Please try again.')
        // Handle errors here
    });
  }

  const handleKeyPress = (event, action) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault(); // Prevents creating a new line
      action();
    }
  };
  

  return (
    <>

      <div className='nav'>
      <a href="#" onClick={() => window.location.reload()}>
        <img className='MainLogo' src={MainLogo} alt='Genie logo in orange. The logo is the Orange letters g, e, n, i, e' />
        </a>
        <h1>Hello, {userDetails.displayName}</h1>
        <div className='creditInfo'>
          <p className='NoC'>{userCredits}</p>
          <div className='creditText'>
            <h3>Credit Balance</h3>
            <p className='addMoreCredits' onClick={() => handleModalOpen('product')}>Want to add more?</p>
          </div>
        </div>
      </div>


      {message && <section><p>{message}</p></section>}

      <Modal show={isModalOpen} handleClose={handleModalClose}/>
      <GenericImageModal imageUrl={`data:image/png;base64,${clickedImageUrl}`} show={modalVisible} handleClose={closeModal} onSave={handleSave}/>

      <div className='MainContainer'>

        <div className='SideBarSection'>
          {!isMenuOpen ? (
          <MenuIcon onClick={toggleMenu} className='open hamburger'/>
        ) : null}

        {isMenuOpen && (
          <>
            <div className='close-menu'>
              <MenuIcon onClick={toggleMenu} className='hamburger'/>
            </div>
            <div className='test'>

              <div className="Sidebar">
                <br/><br/>
                <button onClick={handleHome} className='SideButton'>Home</button>
                <br/>
                <button onClick={() => handleModalOpen('product')} className='SideButton'>
                  <StarIcon className='star'/> Add credits
                </button>
                <br/>
                <button onClick={HandleGallery} className='SideButton'>Image gallery</button>
                <br/>
                {/* <h3>Share with us!</h3> */}
                <div className='logos top'>
                  <a href='https://instagram.com/geni_e.ai?igshid=MzRlODBiNWFlZA==' target="_blank" rel="noopener noreferrer"><img className='social logos' src={insta} alt='Instagram Logo' /></a>
                  <a href='https://www.linkedin.com/company/geni-e' target="_blank" rel="noopener noreferrer"><img className='social logos' src={linked} alt='Linkedin Logo' /></a>
                </div>
                <div className='logos'>
                  <a href='https://www.facebook.com/profile.php?id=61551112913259' target="_blank" rel="noopener noreferrer"><img className='social logos' src={face} alt='facebook Logo' /></a>
                  <a href='https://www.tiktok.com/@geni_e' target="_blank" rel="noopener noreferrer"><img className='social logos' src={tik_tok} alt='tik_tok Logo' /></a>
                </div>
                <br />
                <button className='SideButton' onClick={handleLogout}>Logout</button>
              </div>
            </div>
          </>
        )}
        </div>

        <div className='MainSection'>
          <div className='promptHelp' id="hereLink">
            <h4>
              Stuck on what to prompt? Click <span className='addMore here-button' onClick={() => handleModalOpen('tips')}>here</span>
            </h4>
          </div>



              {/* Render the Modal Component */}
          <Modal
            handleClose={handleModalClose}  // replace with your actual handleClose function
            show={isModalOpen}              // replace with your actual modal visibility state
            contentType={modalContentType}  // the state that tracks what content to show in the modal
          />


          {!isMenuOpen ? (
            <div className='MainUpload'>

            <div className='UploadBox'>
              {!imageUploaded ? (
                <label>
                  <div className='LabelUpload'>
                    <img src={MonoB} alt="Blue geni-e logo"/>
                    <p>Press on geni-e to upload your image and get started.</p>
                  </div>
                  <input type="file" className='Upload' onChange={handleFileUpload} />
                </label>
              ) : (
                <label>
                  <div className='Background'>
                    <img src={uploadedImageUrl} alt="The file that you have Uploaded" className='UploadedImage'/>
                    <input type="file" className='Upload' onChange={handleFileUpload} />
                  </div>
                </label>
              )}
            </div>

            <br/>

            {!isLoading ? (
              <div className='Prompt non-loading'>
                <textarea 
                  placeholder="Enter your style here or leave blank for auto-generation..." 
                  className='TextBox' 
                  onChange={(event) => setTextBoxValue(event.target.value)} 
                  ref={textBoxRef} 
                  rows={4}
                  onKeyDown={(event) => handleKeyPress(event, MainUpload)} // Pass the appropriate handler
                  style={{ padding: '10px', paddingTop: '15px' }}
                />

                {!imageUploaded ? (
                  <img src={SearchIcon} alt='A icon of a magnifying glass' className='Search'/>
                ) : (
                  <img src={SearchIcon} alt='A icon of a magnifying glass' className='Search' onClick={MainUpload}/>
                )}

                {genericImageUrls.length > 0 ? (
                  <div className='arrow'>
                    <img src={DownArrow} alt='two downward pointing arrows in black'/>
                  </div>
                ) : null}  

              </div>
            ) : (
              <div className='Prompt'>
                <textarea 
                  placeholder="Enter your style here or leave blank for auto-generation..." 
                  className='TextBox same-size-textbox' 
                  onChange={(event) => setTextBoxValue(event.target.value)} 
                  ref={textBoxRef} 
                  rows={4} 
                  onKeyDown={(event) => handleKeyPress(event, MainUpload)} // Pass the appropriate handler
                  style={{ padding: '10px', paddingTop: '15px' }} 
                />
                <img className='loading' src={Loading} alt='Loading icon with dashes' />
              </div>
            )}
 
              

            </div>

          ) :

            <div className='MainUpload Open'>

              <div className='UploadBox'>
                {!imageUploaded ? (
                  <label>
                    <div className='LabelUpload SmUpload'>
                      <img src={MonoB} alt="Blue geni-e logo"/>
                      <p>Press on geni-e to upload your image and get started.</p>
                    </div>
                    <input type="file" className='Upload' onChange={handleFileUpload} />
                  </label>
                ) : (
                  <label>
                    <div className='Background SmBackGround'>
                      <img src={uploadedImageUrl} alt="The file that you have Uploaded" className='UploadedImage SmUPIM'/>
                      <input type="file" className='Upload' onChange={handleFileUpload} />
                    </div>
                  </label>
                )}
              </div>

              <br/>

              {!isLoading ? (
                <div className='Prompt non-loading'>
                  <textarea placeholder="Enter your style here or leave blank for auto-generation..." 
                  className='TextBox same-size-textbox' 
                  onChange={(event) => setModifyValue(event.target.value)} 
                  ref={textBoxRef} 
                  rows={4} 
                  onKeyDown={(event) => handleKeyPress(event, ModifyPrompt)} // Pass the appropriate handler
                  style={{ padding: '10px', paddingTop: '15px' }} 
                  />

                  {!imageUploaded ? (
                    <img src={SearchIcon} alt='A icon of a magnifying glass' className='Search'/>
                  ) : (
                    <img src={SearchIcon} alt='A icon of a magnifying glass' className='Search' onClick={MainUpload}/>
                  )}

                  {genericImageUrls.length > 0 ? (
                    <div className='arrow'>
                      <img src={DownArrow} alt='two downward pointing arrows in black'/>
                    </div>
                  ) : null}  

                </div>
              ): (
                <div className='Prompt'>
                  <textarea placeholder="Enter your style here or leave blank for auto-generation..." 
                   className='TextBox same-size-textbox' 
                   onChange={(event) => setModifyValue(event.target.value)} 
                   ref={textBoxRef} 
                   rows={4} 
                   onKeyDown={(event) => handleKeyPress(event, ModifyPrompt)} // Pass the appropriate handler
                   style={{ padding: '10px', paddingTop: '15px' }} 
                 />
                  <img className='loading' src={Loading} alt='Loading icon with dashes' />

                </div>
              )}
              <div>
                <p className='error'>{fileSizeError}</p>
                <p className='error'>{errorMessage}</p>
              </div>      
            </div>

          }

          <br />

          <div className='Generated_Images'>
            {genericImageUrls.length > 0 ? (
              <>
                <h1>Your Generated Images</h1>

                {!isMenuOpen ? (

                  <div className='Images'>
                    {genericImageUrls.map((imageUrl, index) => (
                      <img
                        className="img-without-alt"
                        key={index}
                        src={`data:image/png;base64,${imageUrl}`}
                        alt={`Generated background ${index}`}
                        value={genericBackgroundUrls[index]}
                        onClick={() => handleGenericImageClick({ imageUrl, backgroundUrl: genericBackgroundUrls[index] })}
                      />
                    ))}
                  </div>

                ) :

                  <div className='Images ImOpen'>
                    {genericImageUrls.map((imageUrl, index) => (
                      <img
                        className="img-without-alt"
                        key={index}
                        src={`data:image/png;base64,${imageUrl}`}
                        alt={`Generated background ${index}`}
                        value={genericBackgroundUrls[index]}
                        onClick={() => handleGenericImageClick({ imageUrl, backgroundUrl: genericBackgroundUrls[index] })}
                      />
                    ))}
                  </div>

                }

                <div className='ModifyPrompt'>
                  <h2>Modify Image</h2>

                  {!isModifyLoading ? (
                    <>
                      <div className='Prompt non-loading'>
                        <textarea placeholder="Specify any changes you wish to make here or leave blank for auto-generation..." 
                        className='TextBox same-size-textbox' 
                        onChange={(event) => setModifyValue(event.target.value)} 
                        ref={textBoxRef} 
                        rows={4} 
                        onKeyDown={(event) => handleKeyPress(event, ModifyPrompt)} // Pass the appropriate handler
                        style={{ padding: '10px', paddingTop: '15px' }} 
        />
                        {!imageUploaded ? (
                          <img src={SearchIcon} alt='A icon of a magnifying glass' className='Search'/>
                        ) : (
                          <img src={SearchIcon} alt='A icon of a magnifying glass' className='Search' onClick={ModifyPrompt}/>
                        )}

                      </div>
                      <p className='error2'>{modifyErrorMessage}</p>
                    </>
                  ) : (
                    <div className='Prompt'>
                      <textarea placeholder="Specify any changes you wish to make here or leave blank for auto-generation..." 
                      className='TextBox same-size-textbox' 
                      onChange={(event) => setModifyValue(event.target.value)} 
                      ref={textBoxRef} 
                      rows={4} 
                      onKeyDown={(event) => handleKeyPress(event, ModifyPrompt)} // Pass the appropriate handler
                      style={{ padding: '10px', paddingTop: '15px' }}
                      />
                      <img className='loading' src={Loading} alt='Loading icon with dashes' />
                    </div>
                    )}
                    </div>
              </>
            ) : null}
          </div>
        </div>

      </div>
    </>
  );
}

export default Dashboard;