import React from 'react'

function MobileComponent() {

  return (
    <>
        <p>
            While geni-e is not currently available on mobile devices, we have plans to bring the enchantment of geni-e to smartphones and tablets, but we're not quite there yet.

            <br/><br/>

            In the meantime, geni-e can still be accessed through desktop and laptop computers. We appreciate your understanding and patience as we work to make geni-e mobile-friendly.

            <br/><br/>

            Thank you for your continued support and enthusiasm for geni-e. We can't wait to bring the magic to your fingertips soon.
        </p>
    </>
  )
}

export default MobileComponent