import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged} from 'firebase/auth';
import { initializeApp } from 'firebase/app';

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDnW3Gd5E5zYvOkeCl6jDqyFAyBDVQj_5Q",
    authDomain: "geni-e.firebaseapp.com",
    projectId: "geni-e",
    storageBucket: "geni-e.appspot.com",
    messagingSenderId: "1015653741676",
    appId: "1:1015653741676:web:5f259c1097532485855449"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

function ImageEditor() {
    const navigate = useNavigate();
    const { imageUrl, bgRemovedImage, textBoxValue, height, width } = useParams();
    const canvasRef = useRef(null);
    const [user, setUserDetails] = useState('');
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDragging, setIsDragging] = useState(false);
    const [foregroundSize, setForegroundSize] = useState({ width: width, height: height });
    const API_BASE_URL = 'https://flask.geni-e.com';
  
    useEffect(() => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
  
      const background = new Image();
      background.crossOrigin = 'anonymous';
      background.src = imageUrl;
  
      const foreground = new Image();
      foreground.crossOrigin = 'anonymous';
      foreground.src = bgRemovedImage;
  
      const drawCanvas = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(background, 0, 0, canvas.width, canvas.height);
        ctx.drawImage(foreground, position.x, position.y, foregroundSize.width, foregroundSize.height);
      };
  
      background.onload = () => {
        drawCanvas();
      };

      foreground.onload = () => {
        drawCanvas();
      };
  
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in.
          const { displayName, uid, email } = user;
          setUserDetails({ displayName, uid, email });
        } else {
          // User is signed out.
          setUserDetails(null);
        }
      });

      drawCanvas();
  
      return () => {
        // Clean up the subscription and other resources when the component is unmounted
        unsubscribe();
      };
    }, [imageUrl, bgRemovedImage, position, foregroundSize]);
  
    const handleMouseDown = (e) => {
      setIsDragging(true);
    };
  
    const handleMouseUp = () => {
      setIsDragging(false);
    };
  
    const handleMouseMove = (e) => {
      if (isDragging) {
        const canvas = canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setPosition({ x: x - foregroundSize.width / 2, y: y - foregroundSize.height / 2 });
      }
    };
  
    const handleSizeChange = (e, dimension) => {
      const newSize = parseInt(e.target.value);
      setForegroundSize((prevSize) => ({
        width: dimension === 'width' ? newSize : prevSize.width,
        height: dimension === 'height' ? newSize : prevSize.height,
      }));
    };
  
    const handleSaveClick = () => {
      const canvas = canvasRef.current;
  
      canvas.toBlob(blob => {
          // Create a copy of the Blob object
          const blobCopy = new Blob([blob], { type: blob.type });
  
          // Save the copy to the backend
          const formData = new FormData();
          formData.append('canvas_image', blobCopy, 'canvas_image.png');
          formData.append('prompt', textBoxValue);
          formData.append('background', imageUrl)
          formData.append('uid' , user.uid)
  
          fetch(`${API_BASE_URL}/saveimage`, {
              method: 'POST',
              body: formData
          })
          .then(response => response.json())
          .then(data => {
              console.log('Image uploaded successfully:', data);
          })
          .catch(error => {
              console.error('Error uploading image:', error);
          });
  
          // Create a download link for the original Blob object
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download = 'canvas_image.png';
  
          // Trigger download
          downloadLink.click();
  
          // Revoke object URLs
          URL.revokeObjectURL(downloadLink.href);
      });
    };

    const handleRedirect = () => {
      navigate('/', {replace: true});
    }
  
    return (
      <>
        <button className='RTD' onClick={handleRedirect}>Return To Dashborad</button>
        <div className="canvas-container">
          <canvas
            ref={canvasRef}
            width={800}
            height={600}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          />
        </div>
        <div className='resize'>
          <h1>Image Tools</h1>
          <label>Product Width: </label>
          <input type="number" value={foregroundSize.width} onChange={(e) => handleSizeChange(e, 'width')} />
          <label>Product Height: </label>
          <input type="number" value={foregroundSize.height} onChange={(e) => handleSizeChange(e, 'height')} />
          <button onClick={handleSaveClick}>Download Image</button>
        </div>
      </>
    );
  };

export default ImageEditor;