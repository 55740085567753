import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, updateProfile, fetchSignInMethodsForEmail } from 'firebase/auth';
import Cookies from 'js-cookie';
import GoogleLogin from './GoogleLogin';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDnW3Gd5E5zYvOkeCl6jDqyFAyBDVQj_5Q",
  authDomain: "geni-e.firebaseapp.com",
  projectId: "geni-e",
  storageBucket: "geni-e.appspot.com",
  messagingSenderId: "1015653741676",
  appId: "1:1015653741676:web:5f259c1097532485855449"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth
const auth = getAuth(app);

function EmailPasswordAuth() {
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [error, setError] = useState(null); // State for storing error message
  const [isSignupMode, setIsSignupMode] = useState(false); 
  const navigate = useNavigate();
  const API_BASE_URL = 'https://flask.geni-e.com';

  // Check if the user is already authenticated using the cookie
  useEffect(() => {
    const userCookie = Cookies.get('user');
    if (userCookie) {
      navigate('/dashboard', { replace: true });
    }
  }, [navigate]);


  const handleLogin = () => {
    setError(null); // Clear previous error messages

    if (password.trim() === '') {
      setError('Password cannot be empty'); // Set error message for empty password
      return; // Prevent further execution
    }

    fetchSignInMethodsForEmail(auth, email)
    .then((signInMethods) => {
        if (signInMethods.length === 0) {
            // Email doesn't exist
            setIsSignupMode(true)
        } else {
            // Email exists
            signInWithEmailAndPassword(auth, email, password)
              .then((userCredential) => {
                // Successfully logged in, redirect or perform actions as needed
                Cookies.set('user', 'authenticated', { expires: 1 }); // Cookie expires in 24 hours
                navigate('/dashboard', { replace: true });
              })
              .catch((error) => {
                setError(error.message); // Set error message
                console.error('Firebase Authentication Error:', error.code, error.message);
                setError('The email address and/or password entered is incorrect. Please try again or contact us at hello@geni-e.com.');
            });
        }
    })
    .catch((error) => {
        console.error("Error checking email existence:", error);
    });
  };

  const handleCreateAccount = () => {
    setError(null); // Clear previous error messages
  
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Get the user object from the userCredential
        const user = userCredential.user;
  
        // Add custom details to the user (for example, display name)
        updateProfile(user, {
          displayName: displayName
        })
        .then(() => {
          // Custom details (display name) added successfully
          Cookies.set('user', 'authenticated', { expires: 1 }); // Cookie expires in 24 hours

          const uid = user.uid

          // Send data to Flask backend using fetch
          fetch(`${API_BASE_URL}/createuser`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ displayName, uid }),
          })
          .then(response => response.json())
          .then(data => {
            console.log('Data sent to Flask backend:', data);
          })
          .catch(error => {
            console.error('Error sending data to Flask backend:', error);
          });

          navigate('/dashboard', { replace: true });
        })
        .catch((profileError) => {
          setError(profileError.message); // Set error message for profile update failure
          console.error('Firebase Update Profile Error:', profileError.code, profileError.message);
          setError('Error updating user profile. If you are still experiencing this issue, please contact us at hello@genie-com.');
        });
      })
      .catch((createError) => {
        setError(createError.message); // Set error message for account creation failure
        console.error('Firebase Create Account Error:', createError.code, createError.message);
        setError('Error creating account. If you are still experiencing this issue, please contact us at hello@genie-com.');
      });
  };
  
  
  const handlePasswordReset = () => {
    setError(null); // Clear previous error messages

    if (email) {
      sendPasswordResetEmail(auth, email)
        .then(() => {
          // Password reset email sent successfully
          setSuccessMessage('Password reset email sent successfully. Please check your email.')
        })
        .catch((resetError) => {
          setError(resetError.message); // Set error message
          console.error('Firebase Password Reset Error:', resetError.code, resetError.message);
          setError("It looks like you don't have an account. Please sign up or contact us at hello@genie-com.");
        });
    } else {
      setError('Email field cannot be empty'); // Set error message if email is empty
    }
  };

  return (
    <div className="EmailLogin">
      <input 
        type="email" 
        value={email}
        placeholder="Email"
        onChange={(e) => setEmail(e.target.value)}
      />

      {isSignupMode && (
        <input 
          type="text" 
          value={displayName}
          placeholder="Display Name"
          onChange={(e) => setDisplayName(e.target.value)}
        />
      )}

      <input
        type={showPassword ? 'text' : 'password'}
        placeholder="Password"
        onChange={(e) => setPassword(e.target.value)}  
      />

      <button
        onClick={() => setShowPassword(!showPassword)}
        className ="visPSW"
      >
        {showPassword ? 'Hide' : 'Show'}
      </button>

      <div className='signupmode'>
        <button className='Nw-user' onClick={() => setIsSignupMode(!isSignupMode)}>
          {isSignupMode ? 'Existing user?' : 'New User?'}
        </button>

        {!isSignupMode && (
          <button className="forgot-password" onClick={handlePasswordReset}>
            Forgot Password?
          </button>
        )}
      </div>
      <br/>

      {isSignupMode ? (
        <>
          <button className="SGN-IN" onClick={handleCreateAccount}>
            Sign Up
          </button>
          <div className='line-with-text'>
            <span></span>
            <div className='text'>or</div>
            <span></span>
          </div>
          <div className='SocialBTN'>
            <GoogleLogin />
          </div>
        </>
      ) : (
        <>
          <button className="SGN-IN" onClick={handleLogin}>
            Log in
          </button>
          <div className='line-with-text'>
            <span></span>
            <div className='text'>or</div>
            <span></span>
          </div>
          <div className='SocialBTN'>
            <GoogleLogin />
          </div>
        </>
      )}

      <br />

      {successMessage && <div className="success-message">{successMessage}</div>}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
}

export default EmailPasswordAuth;