import React from 'react';
import { useMediaQuery } from '@react-hook/media-query';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard';
import './App.css';
import ProtectedRoute from './components/ProtectedRoute';
import ImageEditor from './components/Editor/ImageEditor';
import MobileComponent from './components/MobileComponent';
import ImageGallery from './components/Gallery/ImageGallery';

function App() {
  const isMobile = useMediaQuery('(max-width: 1023px)'); // Define your mobile breakpoint

  return (
    <div className='App'>
      {isMobile ?
      <MobileComponent />
      :
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />

            <Route 
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/gallery"
              element={
                <ProtectedRoute>
                  <ImageGallery />
                </ProtectedRoute>
              } 
            />

            <Route 
              path="/image/:imageUrl/:bgRemovedImage/:textBoxValue/:height/:width"
              element={
                <ProtectedRoute>
                  <ImageEditor />
                </ProtectedRoute>
              } 
            />
          </Routes>
        </Router>
      }
    </div>
  );
}

export default App;